@font-face{
  font-family:'Homestuck';
  src:url(fonts/Homestuck-Regular.eot);
  src:url(fonts/Homestuck-Regular.eot?#iefix) format("embedded-opentype"),
  url(fonts/Homestuck-Regular.woff2) format("woff2"),
  url(fonts/Homestuck-Regular.woff) format("woff"),
  url(fonts/Homestuck-Regular.ttf) format("truetype"),
  url(fonts/Homestuck-Regular.svg#Homestuck-Regular) format("svg");
  font-weight:normal;
  font-style:normal
}

body {
  @import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Roboto+Serif&display=swap');  margin: 0;
  font-family: 'Homestuck', sans-serif;
  font-smooth: never;
  text-rendering: geometricPrecision;
  background-color: #001f05;
  color: white;
}

@media (min-width: 768px) {
  body {
    scrollbar-color: rgba(90, 90, 90, 0.3) rgba(90, 90, 90, 0.1);
    scrollbar-width: thin;
  }

  ::-webkit-scrollbar {
    width: 2px;
    height: 10px;
    margin: 20px;
  }
  
  ::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90, 0);
  }
  
  ::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0);
  }
}